<template>
  <div class="building" v-if="license">
    <v-tabs
      v-model="tab"
      :color="whiteLabel.colors.primary"
      icons-and-text
      show-arrows
      center-active
      fixed-tabs
      align-with-title
    >
      <v-tab key="mode">
        Modo
        <v-icon>mdi-book-open-variant</v-icon>
      </v-tab>

      <v-tab key="time">
        Tiempos
        <v-icon>mdi-progress-clock</v-icon>
      </v-tab>

      <v-tab key="screen" v-if="license.gate">
        Pantalla
        <v-icon>mdi-invert-colors</v-icon>
      </v-tab>

      <v-tab key="search" v-if="license.gate">
        Buscador
        <v-icon>mdi-home-search</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="mode">
        <v-container>
          <v-card class="mt-4 mb-6">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-book-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Navegación de unidades
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-radio-group v-model="mode" @change="updateModes()">
                <v-radio :value="true" label="Plantas" :color="whiteLabel.colors.primary"> </v-radio>
                <div class="caption font-weight-light pb-6">
                  Navegación por plantas para la selección de unidad.
                </div>
                <v-radio :value="false" label="Directorio" :color="whiteLabel.colors.primary"></v-radio>
                <div class="caption font-weight-light">
                  Navegación por directorio alfabético para la selección de
                  unidad.
                </div>
              </v-radio-group>
            </v-list-item>
          </v-card>

          <v-card class="mt-4 mb-6">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-sort</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Orden de Plantas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-radio-group v-model="mode_sort" @change="updateModes()">
                <v-radio :value="false" label="Ascendente" :color="whiteLabel.colors.primary"> </v-radio>
                <v-radio :value="true" label="Descendente" :color="whiteLabel.colors.primary"></v-radio>
              </v-radio-group>
            </v-list-item>
          </v-card>

          <v-card class="mt-4 mb-6" v-if="license.gate">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-phone-missed</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Reintentos de llamada
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-radio-group v-model="call_repetitions" @change="updateModes()">
                <v-radio :value="1" label="1 ciclo" :color="whiteLabel.colors.primary"> </v-radio>
                <v-radio :value="2" label="2 ciclos" :color="whiteLabel.colors.primary"></v-radio>
                <v-radio :value="3" label="3 ciclos" :color="whiteLabel.colors.primary"></v-radio>
                <div class="caption font-weight-light">
                  Para unidades en modo audio, durante el marcado que no es
                  atendido por ninguno de los residentess asociados, indique la
                  cantidad ciclos de reintento de marcado.
                </div>
              </v-radio-group>
            </v-list-item>
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="time">
        <v-container>
          <v-card class="mt-4 mb-6">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-timer-sand</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Cuenta regresiva
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="pt-10 pb-5" v-if="license.gate">
              <v-slider
                v-model="idle_time"
                min="1"
                max="300"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Tiempo sin actividad para pasar a modo reposo (en segundos)"
                persistent-hint
                thumb-label="always"
                label="Reposo"
                @end="updateTimes()"
              ></v-slider>
            </v-list-item>

            <v-list-item class="pt-10 pb-5" v-if="license.gate">
              <v-slider
                v-model="idle_floor_guard_emergency_time"
                min="1"
                max="300"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Tiempo sin actividad para volver al inicio desde plantas, seguridad y emergencias (en segundos)"
                persistent-hint
                thumb-label="always"
                label="Plantas"
                @end="updateTimes()"
              ></v-slider>
            </v-list-item>

            <v-list-item class="pt-10 pb-5">
              <v-slider
                v-model="call_time"
                min="1"
                max="300"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Tiempo de duración de una llamada (en segundos)"
                persistent-hint
                thumb-label="always"
                label="Llamada"
                @end="updateTimes()"
              ></v-slider>
            </v-list-item>

            <v-list-item class="pt-10 pb-5" v-if="license.gate">
              <v-slider
                v-model="call_guard_emergency_time"
                min="1"
                max="300"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Tiempo de duración de una llamada a la guardia o de emergencia (en segundos)"
                persistent-hint
                thumb-label="always"
                label="Guardia"
                @end="updateTimes()"
              ></v-slider>
            </v-list-item>

            <v-list-item class="pt-10 pb-5" v-if="license.gate">
              <v-slider
                v-model="idle_message_time"
                min="1"
                max="300"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Tiempo sin actividad para volver al inicio desde la pantalla de envío de mensajes SMS (en segundos)"
                persistent-hint
                thumb-label="always"
                label="Mensajes"
                @end="updateTimes()"
              ></v-slider>
            </v-list-item>

            <v-list-item class="pt-10 pb-5" v-if="license.gate">
              <v-slider
                v-model="idle_admin_time"
                min="1"
                max="300"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Tiempo sin actividad para volver al inicio desde la pantalla de administración (en segundos)"
                persistent-hint
                thumb-label="always"
                label="Administrar"
                @end="updateTimes()"
              ></v-slider>
            </v-list-item>
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="screen" v-if="license.gate">
        <v-container>
          <v-card class="mt-4 mb-6">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-format-color-fill</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Paleta de colores
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="pt-5 pb-5">
              <v-list-item-content>
                <v-radio-group
                  v-model="theme"
                  @change="updateTheme()"
                  style="width: 100%"
                >
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div
                        class="text-center ml-3 mt-5 mb-4 d-flex justify-start"
                      >
                        <div style="width: 100px; padding-top: 10px">
                          <v-radio :value="0" :label="whiteLabel.labels.brand" :color="whiteLabel.colors.primary"> </v-radio>
                        </div>
                        <div>
                          <table>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #3f51b5"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #03a9f4"
                              ></td>
                            </tr>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #5867bc"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #ffffff"
                              ></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div
                        class="text-center ml-3 mt-5 mb-4 d-flex justify-start"
                      >
                        <div style="width: 100px; padding-top: 10px">
                          <v-radio :value="1" label="Fuego" :color="whiteLabel.colors.primary"></v-radio>
                        </div>
                        <div>
                          <table>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #b5513f"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #f4a903"
                              ></td>
                            </tr>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #bc6758"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #ffffff"
                              ></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <div
                        class="text-center ml-3 mt-5 mb-4 d-flex justify-start"
                      >
                        <div style="width: 100px; padding-top: 10px">
                          <v-radio :value="2" label="Eco" :color="whiteLabel.colors.primary"> </v-radio>
                        </div>
                        <div>
                          <table>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #349649"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #44c802"
                              ></td>
                            </tr>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #277237"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #ffffff"
                              ></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div
                        class="text-center ml-3 mt-5 mb-4 d-flex justify-start"
                      >
                        <div style="width: 100px; padding-top: 10px">
                          <v-radio :value="3" label="Uva" :color="whiteLabel.colors.primary"></v-radio>
                        </div>
                        <div>
                          <table>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #b53f75"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #f41503"
                              ></td>
                            </tr>
                            <tr>
                              <td
                                class="sqare"
                                style="background-color: #892f58"
                              ></td>
                              <td
                                class="sqare"
                                style="background-color: #ffffff"
                              ></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card class="mt-4 mb-6" v-if="license.gate">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-brightness-6</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Brillo de pantalla
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="pt-10 pb-5">
              <v-slider
                v-model="brightness_active"
                min="1"
                max="100"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Brillo de pantalla en modo activo (en porcentaje)"
                persistent-hint
                thumb-label="always"
                label="Modo activo"
                @end="updateBrightness()"
              ></v-slider>
            </v-list-item>
            <v-list-item class="pt-10 pb-5">
              <v-slider
                v-model="brightness_idle"
                min="1"
                max="100"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Brillo de pantalla en modo reposo (en porcentaje)"
                persistent-hint
                thumb-label="always"
                label="Modo reposo"
                @end="updateBrightness()"
              ></v-slider>
            </v-list-item>
          </v-card>

          <v-card class="mt-4 mb-6" v-if="license.gate">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-screen-rotation</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Rotación de pantalla
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="pt-5 pb-5">
              <v-list-item-content>
                <v-checkbox
                  v-model="rotation"
                  :color="whiteLabel.colors.primary"
                  label="Rotar la pantalla 180 grados"
                  @change="updateRotation"
                ></v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="search" v-if="license.gate">
        <v-container>
          <v-card class="mt-4 mb-6">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-card-search-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Buscador
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="pt-5">
              <v-list-item-content>
                <v-switch
                  v-model="search_active"
                  color="success"
                  inset
                  class="v-input--reverse v-input--expand"
                  @change="updateSearch()"
                >
                  <template #label> Activar Buscador </template>
                </v-switch>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="search_active"></v-divider>

            <v-list-item class="pt-12" v-if="search_active">
              <v-slider
                v-model="idle_search_time"
                min="1"
                max="300"
                :color="whiteLabel.colors.primary"
                :track-color="whiteLabel.colors.secondary"
                hint="Tiempo sin actividad para volver al inicio desde el buscador (en segundos)"
                persistent-hint
                thumb-label="always"
                label="Tiempo Buscador"
                @end="updateSearch()"
              ></v-slider>
            </v-list-item>

            <v-list-item v-if="search_active">
              <v-list-item-content>
                <v-switch
                  v-model="search_alias"
                  color="success"
                  inset
                  class="v-input--reverse v-input--expand"
                  @change="updateSearch()"
                >
                  <template #label> Buscar sobre campo Alias </template>
                </v-switch>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "@/fb";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    tab: 0,
    mode: true,
    mode_sort: true,
    call_repetitions: 2,
    range: [1, 300],
    idle_time: 120,
    idle_floor_guard_emergency_time: 10,
    idle_search_time: 15,
    idle_message_time: 240,
    idle_admin_time: 300,
    call_time: 60,
    call_guard_emergency_time: 30,
    theme: 0,
    brightness_active: 100,
    brightness_idle: 20,
    rotation: false,
    search_active: false,
    search_alias: false,
    doors: [
      { name: "Puerta", dtmf: 5, time: 3 },
      { name: "Portón", dtmf: 0, time: 3 },
      { name: "Barrera", dtmf: 8, time: 3 },
    ],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
  }),

  created() {},

  mounted() {
    this.snackbar.visible = true;
    this.snackbar.text = "Cargando datos ...";
    this.snackbar.color = "success";
    this.getBuilding();
  },

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
  },

  methods: {
    async getBuilding() {
      try {
        await db
          .doc("buildings/" + this.user.data.building)
          .get()
          .then((building) => {
            this.mode = building.data().settings.mode;
            this.mode_sort = building.data().settings.mode_sort;
            this.call_repetitions = building.data().settings.call_repetitions;
            this.idle_time = building.data().settings.idle_time;
            this.idle_floor_guard_emergency_time = building.data().settings.idle_floor_guard_emergency_time;
            this.idle_search_time = building.data().settings.idle_search_time;
            this.idle_message_time = building.data().settings.idle_message_time;
            this.idle_admin_time = building.data().settings.idle_admin_time;
            this.call_time = building.data().settings.call_time;
            this.call_guard_emergency_time = building.data().settings.call_guard_emergency_time;
            this.theme = building.data().settings.theme;
            this.search_active = building.data().settings.search_active;
            this.search_alias = building.data().settings.search_alias;
            this.brightness_active = building.data().settings.brightness_active;
            this.brightness_idle = building.data().settings.brightness_idle;
            this.rotation = building.data().settings.rotation
              ? building.data().settings.rotation
              : false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    async updateModes() {
      try {
        this.snackbar.visible = true;
        this.snackbar.text = "Guardando datos ...";
        this.snackbar.color = "success";
        await db
          .doc("buildings/" + this.user.data.building)
          .update({
            "settings.mode": this.mode,
            "settings.mode_sort": this.mode_sort,
            "settings.call_repetitions": this.call_repetitions,
          })
          .then(() => {
            //
          });
      } catch (error) {
        console.log(error);
      }
    },

    async updateTimes() {
      try {
        this.snackbar.visible = true;
        this.snackbar.text = "Guardando datos ...";
        this.snackbar.color = "success";
        await db
          .doc("buildings/" + this.user.data.building)
          .update({
            "settings.idle_time": this.idle_time,
            "settings.idle_floor_guard_emergency_time": this
              .idle_floor_guard_emergency_time,
            "settings.idle_message_time": this.idle_message_time,
            "settings.idle_admin_time": this.idle_admin_time,
            "settings.call_time": this.call_time,
            "settings.call_guard_emergency_time": this
              .call_guard_emergency_time,
          })
          .then(() => {
            //
          });
      } catch (error) {
        console.log(error);
      }
    },

    async updateTheme() {
      try {
        this.snackbar.visible = true;
        this.snackbar.text = "Guardando datos ...";
        this.snackbar.color = "success";
        await db
          .doc("buildings/" + this.user.data.building)
          .update({
            "settings.theme": this.theme,
          })
          .then(() => {
            //
          });
      } catch (error) {
        console.log(error);
      }
    },

    async updateSearch() {
      try {
        this.snackbar.visible = true;
        this.snackbar.text = "Guardando datos ...";
        this.snackbar.color = "success";
        await db
          .doc("buildings/" + this.user.data.building)
          .update({
            "settings.search_active": this.search_active,
            "settings.search_alias": this.search_alias,
            "settings.idle_search_time": this.idle_search_time,
          })
          .then(() => {
            //
          });
      } catch (error) {
        console.log(error);
      }
    },

    async updateBrightness() {
      try {
        this.snackbar.visible = true;
        this.snackbar.text = "Guardando datos ...";
        this.snackbar.color = "success";
        await db
          .doc("buildings/" + this.user.data.building)
          .update({
            "settings.brightness_active": this.brightness_active,
            "settings.brightness_idle": this.brightness_idle,
          })
          .then(() => {
            //
          });
      } catch (error) {
        console.log(error);
      }
    },

    async updateRotation() {
      try {
        this.snackbar.visible = true;
        this.snackbar.text = "Guardando datos ...";
        this.snackbar.color = "success";
        await db
          .doc("buildings/" + this.user.data.building)
          .update({
            "settings.rotation": this.rotation,
          })
          .then(() => {
            //
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input__icon {
  i {
    font-size: 10px;
  }
}

.sqare {
  width: 20px;
  height: 20px;
  border: 1px solid black;
}
</style>
